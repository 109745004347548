<template>
  <div class="waste-destination-form text-start">
    <v-form>
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('conventional_collection.term_1') }}
          <strong>{{ mountTranslate('conventional_collection.term_2') }}</strong>
          {{ mountTranslate('conventional_collection.term_3') }}
        </label>
        <v-radio-group
          v-model="questions.question1"
          class="radio-default"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('conventional_collection.no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('conventional_collection.yes')"
            :value="true"
          />
        </v-radio-group>
      </div>

      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('city_halls_served_conventional.label') }}
        </label>
        <v-textarea
          v-model="questions.question2"
          flat
          background-color="field_register"
          class="input-register mt-4"
          :label="mountTranslate('city_halls_served_conventional.placeholder')"
          solo
        />
      </div>

      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('selective_collect.term_1') }}
          <strong>{{ mountTranslate('selective_collect.term_2') }}</strong>
          {{ mountTranslate('selective_collect.term_3') }}
        </label>
        <v-radio-group
          v-model="questions.question3"
          class="radio-default"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('selective_collect.no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('selective_collect.yes')"
            :value="true"
          />
        </v-radio-group>
      </div>

      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('city_halls_served_selective.label') }}
        </label>
        <v-textarea
          v-model="questions.question4"
          flat
          background-color="field_register"
          class="input-register mt-4"
          :label="mountTranslate('city_halls_served_selective.placeholder')"
          solo
        />
      </div>

      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('has_contract.label') }}
        </label>
        <v-textarea
          v-model="questions.question5"
          flat
          background-color="field_register"
          class="input-register mt-4"
          :label="mountTranslate('has_contract.placeholder')"
          solo
        />
      </div>

      <div class="content-field">
        <label
          class="title-field"
        >
          {{ mountTranslate('how_many_trucks.label') }}
        </label>
        <div>
          <v-select
            v-model="questions.question6"
            :items="how_many_trucks_select"
            :placeholder="mountTranslate('how_many_trucks.selects.placeholder')"
            class="select-register mt-4"
            background-color="white"
            outlined
          />
        </div>
      </div>

      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('private_company.term_1') }}
          <strong>{{ mountTranslate('private_company.term_2') }}</strong>
          {{ mountTranslate('private_company.term_3') }}
        </label>
        <v-radio-group
          v-model="questions.question7"
          class="radio-default"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('private_company.no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('private_company.yes')"
            :value="true"
          />
        </v-radio-group>
      </div>

      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('company_served.label') }}
        </label>
        <v-textarea
          v-model="questions.question8"
          flat
          background-color="field_register"
          class="input-register mt-4"
          :label="mountTranslate('company_served.placeholder')"
          solo
        />
      </div>

      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('top_sellers.label') }}
        </label>

        <div class="check-screening">
          <v-checkbox
            v-model="questions.question9.manufacturers"
            color="primary"
            hide-details
          />
          <div class="label-terms">
            {{ mountTranslate('top_sellers.select_1') }}
          </div>
        </div>

        <div class="check-screening">
          <v-checkbox
            v-model="questions.question9.recyclers"
            color="primary"
            hide-details
          />
          <div class="label-terms">
            {{ mountTranslate('top_sellers.select_2') }}
          </div>
        </div>

        <div class="check-screening">
          <v-checkbox
            v-model="questions.question9.intermediaries"
            color="primary"
            hide-details
          />
          <div class="label-terms">
            {{ mountTranslate('top_sellers.select_3') }}
          </div>
        </div>

        <div class="check-screening">
          <v-checkbox
            v-model="questions.question9.other"
            color="primary"
            hide-details
          />
          <div class="label-terms">
            {{ mountTranslate('top_sellers.select_4') }}
          </div>
        </div>

        <v-text-field
          v-if="questions.question9.other"
          v-model="questions.question9.description"
          flat
          background-color="field_register"
          class="input-register mt-4"
          :label="mountTranslate('top_sellers.placeholder')"
          solo
        />
      </div>

      <div class="button-access">
        <rectoplus-button
          class="next-button"
          :text="$t('components.register.business.organization.button')"
          @click="nextStep"
        />
      </div>
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'FormWasteDestination',
  components: {
    RectoplusButton,
  },
  data() {
    return {
      questions: {
        question1: false,
        question2: '',
        question3: false,
        question4: '',
        question5: '',
        question6: '',
        question7: false,
        question8: '',
        question9: {
          manufacturers: false,
          recyclers: false,
          intermediaries: false,
          other: false,
          description: '',
        },
      },
      how_many_trucks_select: [
        { text: this.mountTranslate('how_many_trucks.selects.select_0'), value: this.mountTranslate('how_many_trucks.selects.select_0') },
        { text: this.mountTranslate('how_many_trucks.selects.select_1'), value: this.mountTranslate('how_many_trucks.selects.select_1') },
        { text: this.mountTranslate('how_many_trucks.selects.select_2'), value: this.mountTranslate('how_many_trucks.selects.select_2') },
        { text: this.mountTranslate('how_many_trucks.selects.select_3'), value: this.mountTranslate('how_many_trucks.selects.select_3') },
        { text: this.mountTranslate('how_many_trucks.selects.select_4'), value: this.mountTranslate('how_many_trucks.selects.select_4') },
        { text: this.mountTranslate('how_many_trucks.selects.select_5'), value: this.mountTranslate('how_many_trucks.selects.select_5') },
        { text: this.mountTranslate('how_many_trucks.selects.select_6'), value: this.mountTranslate('how_many_trucks.selects.select_6') },
      ],
    };
  },
  computed: {
    ...mapGetters(['getWasteDestination', 'hasOperationalCapacity']),
  },
  mounted() {
    if (this.hasOperationalCapacity) this.setQuestions();
  },
  methods: {
    ...mapActions([
      'nextSubStep',
      'setQuestionWasteDestination',
    ]),
    mountTranslate(path) {
      return this.$t(`components.stepper_business.sub_steps.waste_destination.form.${path}`);
    },
    nextStep() {
      this.setQuestionWasteDestination(this.questions);
      this.nextSubStep();
    },
    setQuestions() {
      Object.keys(this.questions).forEach((key) => {
        this.questions[key] = this.getWasteDestination[key];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.waste-destination-form {
  color: $color_field_quiz;
  font-size: 0.875em;

  .select-register {
    color: $color_primary;
    border-radius: 10px;
    width: 100%;
  }

  .radio-default {
    font-weight: bold;
  }

  .content-field {
    margin-bottom: 20px;
  }

  .title-field {
    font-size: 1.25em;
    font-weight: 600;
  }

  .check-screening {
    display: flex;
    align-items: flex-end;

    .label-terms {
      font-size: 1.095em;
      font-weight: bold;
      margin-left: 11px;
    }
  }

  .button-access {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 40px;

    .next-button {
      width: 100%;
      height: 70px;
      margin-bottom: 20px;
    }
  }

  .input-register{
    border-radius: 8px;
  }
}
</style>
