<template>
  <div class="administrative">
    <div>
      <p class="title-text">
        {{ $t('components.stepper_business.sub_steps.title') }}
      </p>

      <p class="subtitle-text">
        {{ $t('components.stepper_business.sub_steps.administrative.title') }}
      </p>
      <form-administrative v-if="checkBusinessKind" />
      <waste-merchant-administrative v-else />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import FormAdministrative from '@/components/forms/register/business/operational_capacity/FormAdministrative.vue';
import WasteMerchantAdministrative from '@/components/forms/register/business/operational_capacity/waste_merchant/WasteMerchantAdministrative.vue';

export default {
  name: 'Administrative',
  components: {
    FormAdministrative,
    WasteMerchantAdministrative,
  },
  computed: {
    ...mapGetters([
      'getBusiness',
    ]),
    checkBusinessKind() {
      return this.getBusiness.kind === 'cooperative_association';
    },
  },
};
</script>

<style lang="scss" scoped>
.administrative {
  padding-left: 8px;
  margin: 20px;
  .title-text {
    color: $title_color_primary;
    margin-bottom: 64px;
    margin-top: 40px;
    font-size: 1.8em;
    font-weight: bold;
  }
  .subtitle-text {
    color: $title_color_primary;
    margin-bottom: 30px;
    font-size: 1.5em;
    font-weight: 600;
  }
}
</style>
