<template>
  <div class="programs-partnerships">
    <p class="title-text">
      {{ $t('components.stepper_business.sub_steps.title') }}
    </p>

    <p class="subtitle-text">
      {{ $t('components.stepper_business.sub_steps.programs_partnerships.title') }}
    </p>
    <form-programs-partnerships v-if="checkBusinessKind" />
    <waste-merchant-form-programs-partnerships v-else />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import FormProgramsPartnerships from '@/components/forms/register/business/operational_capacity/FormProgramsPartnerships.vue';
import WasteMerchantFormProgramsPartnerships from '@/components/forms/register/business/operational_capacity/waste_merchant/WasteMerchantFormProgramsPartnerships.vue';

export default {
  name: 'ProgramsPartnerships',
  components: {
    FormProgramsPartnerships,
    WasteMerchantFormProgramsPartnerships,
  },
  computed: {
    ...mapGetters([
      'getBusiness',
    ]),
    checkBusinessKind() {
      return this.getBusiness.kind === 'cooperative_association';
    },
  },
};
</script>

<style lang="scss" scoped>
.programs-partnerships {
  padding-left: 8px;
  margin: 20px;
  .title-text {
    color: $title_color_primary;
    margin-bottom: 64px;
    margin-top: 40px;
    font-size: 1.8em;
    font-weight: bold;
  }
  .subtitle-text {
    color: $title_color_primary;
    margin-bottom: 30px;
    font-size: 1.5em;
    font-weight: 600;
  }
}
</style>
