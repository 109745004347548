<template>
  <div class="conclusion">
    <p class="title-text">
      {{ $t('components.stepper_business.sub_steps.title') }}
    </p>

    <p class="subtitle-text">
      {{ $t('components.stepper_business.sub_steps.conclusion.title') }}
    </p>
    <form-conclusion />
  </div>
</template>
<script>
import FormConclusion from '@/components/forms/register/business/operational_capacity/FormConclusion.vue';

export default {
  name: 'Conclusion',
  components: {
    FormConclusion,
  },
};
</script>

<style lang="scss" scoped>
.conclusion {
  padding-left: 8px;
  margin: 20px;
  .title-text {
    color: $title_color_primary;
    margin-bottom: 64px;
    margin-top: 40px;
    font-size: 1.8em;
    font-weight: bold;
  }
  .subtitle-text {
    color: $title_color_primary;
    margin-bottom: 30px;
    font-size: 1.5em;
    font-weight: 600;
  }
}
</style>
