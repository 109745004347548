<template>
  <div class="waste-destination">
    <p class="title-text">
      {{ $t('components.stepper_business.sub_steps.title') }}
    </p>

    <p class="subtitle-text">
      {{ $t('components.stepper_business.sub_steps.waste_destination.title') }}
    </p>
    <form-waste-destination v-if="checkBusinessKind" />
    <waste-merchant-waste-destination v-else />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import FormWasteDestination from '@/components/forms/register/business/operational_capacity/FormWasteDestination.vue';
import WasteMerchantWasteDestination from '@/components/forms/register/business/operational_capacity/waste_merchant/WasteMerchantWasteDestination.vue';

export default {
  name: 'WasteDestination',
  components: {
    FormWasteDestination,
    WasteMerchantWasteDestination,
  },
  computed: {
    ...mapGetters([
      'getBusiness',
    ]),
    checkBusinessKind() {
      return this.getBusiness.kind === 'cooperative_association';
    },
  },
};
</script>

<style lang="scss" scoped>
.waste-destination {
  padding-left: 8px;
  margin: 20px;
  .title-text {
    color: $title_color_primary;
    margin-bottom: 64px;
    margin-top: 40px;
    font-size: 1.8em;
    font-weight: bold;
  }
  .subtitle-text {
    color: $title_color_primary;
    margin-bottom: 30px;
    font-size: 1.5em;
    font-weight: 600;
  }
}
</style>
