<template>
  <div class="administrative-form">
    <v-form>
      <div>
        <label class="title-field">
          {{ mountTranslate('responsible_name') }}
        </label>
        <v-text-field
          v-model="questions.question1.responsible_name"
          flat
          background-color="field_register"
          class="input-register mt-4"
          :label="mountTranslate('responsible_label')"
          solo
        />
      </div>
      <div>
        <label class="title-field">
          {{ mountTranslate('constitution_date') }}
        </label>
        <v-text-field
          v-model="questions.question2.constitution_date"
          v-mask="'##/##/####'"
          flat
          background-color="field_register"
          class="input-register field-date mt-4"
          :label="mountTranslate('constitution_date_label')"
          solo
        />
      </div>
      <div>
        <label class="title-field">
          {{ mountTranslate('has_legal_representative') }}
        </label>
        <v-radio-group
          v-model="questions.question3.hasLegalRepresentative"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('yes')"
            :value="true"
          />
        </v-radio-group>
        <v-text-field
          v-if="questions.question3.hasLegalRepresentative"
          v-model="questions.question3.representative_name"
          flat
          background-color="field_register"
          class="input-register"
          :label="mountTranslate('has_legal_representative_label')"
          solo
        />
        <label
          v-if="questions.question3.hasLegalRepresentative"
          class="title-field"
        >
          {{ mountTranslate('representative_contact') }}
        </label>
        <v-text-field
          v-if="questions.question3.hasLegalRepresentative"
          v-model="questions.question3.representative_contact"
          flat
          background-color="field_register mt-4"
          class="input-register input-contact"
          :label="mountTranslate('representative_contact_placeholder')"
          solo
        />
      </div>
      <div>
        <label class="title-field">
          {{ mountTranslate('has_audit') }}
        </label>
        <v-radio-group
          v-model="questions.question4.hasAudit"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('yes')"
            :value="true"
          />
        </v-radio-group>
        <v-textarea
          v-if="questions.question4.hasAudit"
          v-model="questions.question4.describe_about"
          flat
          background-color="field_register"
          class="input-register"
          :label="mountTranslate('describe_about')"
          solo
        />
      </div>
      <div>
        <label class="title-field">
          {{ mountTranslate('has_software') }}
        </label>
        <v-radio-group
          v-model="questions.question5.hasSoftware"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('yes')"
            :value="true"
          />
        </v-radio-group>
        <v-text-field
          v-if="questions.question5.hasSoftware"
          v-model="questions.question5.softwareName"
          flat
          background-color="field_register"
          class="input-register mt-4"
          :label="mountTranslate('software_name')"
          solo
        />
      </div>
      <div>
        <label class="title-field">
          {{ mountTranslate('has_customers_register') }}
        </label>
        <v-radio-group
          v-model="questions.question6"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('yes')"
            :value="true"
          />
        </v-radio-group>
      </div>
      <div>
        <label class="title-field">
          {{ mountTranslate('ERP_register') }}
        </label>
        <v-radio-group
          v-model="questions.question7"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('yes')"
            :value="true"
          />
        </v-radio-group>
      </div>
      <div class="button-access">
        <rectoplus-button
          id="nextSubStep1"
          class="next-button"
          :text="$t('components.register.business.organization.button')"
          @click="nextStep"
        />
      </div>
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'WasteMerchantAdministrative',
  components: {
    RectoplusButton,
  },
  data() {
    return {
      questions: {
        question1: {
          responsible_name: '',
        },
        question2: {
          constitution_date: '',
        },
        question3: {
          hasLegalRepresentative: false,
          representative_contact: '',
        },
        question4: {
          hasAudit: false,
          describe_about: '',
        },
        question5: {
          hasSoftware: false,
          softwareName: '',
        },
        question6: false,
        question7: false,
      },
    };
  },
  computed: {
    ...mapGetters(['getAdministrative', 'hasOperationalCapacity']),
  },
  mounted() {
    if (this.hasOperationalCapacity) this.setQuestions();
  },
  methods: {
    ...mapActions([
      'nextSubStep',
      'setQuestionAdministrative',
    ]),
    mountTranslate(path) {
      return this.$t(`components.stepper_business.sub_steps.waste_merchant.administrative.form.${path}`);
    },
    nextStep() {
      this.setQuestionAdministrative(this.questions);
      this.nextSubStep();
    },
    setQuestions() {
      Object.keys(this.questions).forEach((key) => {
        this.questions[key] = this.getAdministrative[key];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .administrative-form {
    color: $color_field_quiz;
    font-size: 0.875em;
    .content-field {
      margin-bottom: 20px;
    }
    .radio-company {
      font-weight: bold;
    }
    .title-field {
      font-size: 1.25em;
      font-weight: 600;
    }
    .field-date {
      width: 168px;
    }
    .button-access {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 40px;
      .next-button {
        width: 100%;
        height: 70px;
        margin-bottom: 20px;
      }
    }
    .input-register{
      border-radius: 8px;
    }
    .input-contact {
      border-radius: 8px;
      width: 50%;
    }
  }
</style>
