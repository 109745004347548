<template>
  <div class="administrative-form">
    <v-form>
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('company.label') }}
        </label>
        <v-radio-group
          v-model="questions.question1.hasCompany"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('cooperative')"
            value="cooperative"
          />
          <v-radio
            class="mb-5"
            :label="mountTranslate('association')"
            value="association"
          />
          <v-radio
            :label="mountTranslate('other')"
            value="other"
          />
        </v-radio-group>
        <v-text-field
          v-if="questions.question1.hasCompany === 'other'"
          v-model="questions.question1.other"
          flat
          background-color="field_register"
          class="input-register"
          :label="mountTranslate('cooperative_network.placeholder')"
          solo
        />
      </div>
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('cooperative_network.label') }}
        </label>
        <v-radio-group
          v-model="questions.question2.hasCooperativeNetwork"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('cooperative_network.no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('cooperative_network.yes')"
            :value="true"
          />
        </v-radio-group>
        <v-text-field
          v-if="questions.question2.hasCooperativeNetwork"
          v-model="questions.question2.name"
          flat
          background-color="field_register"
          class="input-register"
          :label="mountTranslate('cooperative_network.placeholder')"
          solo
        />
      </div>
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('has_software.label') }}
        </label>
        <v-radio-group
          v-model="questions.question3.hasSoftware"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('has_software.no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('has_software.yes')"
            :value="true"
          />
        </v-radio-group>
        <v-text-field
          v-if="questions.question3.hasSoftware"
          v-model="questions.question3.name"
          flat
          background-color="field_register"
          class="input-register"
          :label="mountTranslate('has_software.placeholder')"
          solo
        />
      </div>
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('has_customer_base.label') }}
        </label>
        <v-radio-group
          v-model="questions.question4"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('has_customer_base.no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('has_customer_base.yes')"
            :value="true"
          />
        </v-radio-group>
      </div>

      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('remuneration_company.label') }}
        </label>
        <v-textarea
          v-model="questions.question5"
          flat
          background-color="field_register"
          class="input-register mt-4"
          :label="mountTranslate('remuneration_company.placeholder')"
          solo
        />
      </div>

      <label class="title-field">
        {{ mountTranslate('financial_structure_components.label') }}
      </label>

      <div class="check-financial-structure">
        <v-checkbox
          v-model="questions.question6.hasQuotas"
          color="primary"
          value="success"
          hide-details
        />
        <div class="label-terms">
          {{ mountTranslate('financial_structure_components.quotas') }}
        </div>
      </div>

      <div class="check-financial-structure">
        <v-checkbox
          v-model="questions.question6.hasReserveFund"
          color="primary"
          value="success"
          hide-details
        />
        <div class="label-terms">
          {{ mountTranslate('financial_structure_components.reserve_fund') }}
        </div>
      </div>

      <div class="check-financial-structure">
        <v-checkbox
          v-model="questions.question6.hasNone"
          color="primary"
          value="success"
          hide-details
        />
        <div class="label-terms">
          {{ mountTranslate('financial_structure_components.none') }}
        </div>
      </div>

      <div class="check-financial-structure mb-4">
        <v-checkbox
          v-model="questions.question6.hasOther"
          color="primary"
          value="success"
          hide-details
        />
        <div class="label-terms">
          {{ mountTranslate('financial_structure_components.other') }}
        </div>
      </div>

      <v-text-field
        v-if="questions.question6.hasOther"
        v-model="questions.question6.nameOther"
        flat
        background-color="field_register"
        class="input-register"
        :label="mountTranslate('financial_structure_components.placeholder')"
        solo
      />
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('has_attorney.label') }}
        </label>
        <v-radio-group
          v-model="questions.question7.hasAttorney"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('has_attorney.no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('has_attorney.yes')"
            :value="true"
          />
        </v-radio-group>
        <div
          v-if="questions.question7.hasAttorney"
        >
          <v-text-field
            v-model="questions.question7.nameAttorney"
            flat
            background-color="field_register"
            class="input-register"
            :label="mountTranslate('has_attorney.placeholder')"
            solo
          />

          <label class="title-field">
            {{ mountTranslate('has_attorney.contact.label') }}
          </label>
          <v-text-field
            v-model="questions.question7.contactAttorney"
            v-mask="'(##) #####-####'"
            flat
            background-color="field_register"
            class="input-contact mt-4"
            :label="mountTranslate('has_counter.contact.placeholder')"
            solo
          />
        </div>
      </div>
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('has_counter.label') }}
        </label>
        <v-radio-group
          v-model="questions.question8.hasCounter"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('has_counter.no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('has_counter.yes')"
            :value="true"
          />
        </v-radio-group>
        <div v-if="questions.question8.hasCounter">
          <v-text-field
            v-model="questions.question8.nameCounter"
            flat
            background-color="field_register"
            class="input-register"
            :label="mountTranslate('has_counter.placeholder')"
            solo
          />
          <label class="title-field">
            {{ mountTranslate('has_counter.contact.label') }}
          </label>
          <v-text-field
            v-model="questions.question8.contactCounter"
            v-mask="'(##) #####-####'"
            flat
            background-color="field_register"
            class="input-contact mt-4"
            :label="mountTranslate('has_counter.contact.placeholder')"
            solo
          />
        </div>
      </div>
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('clt_employee.label') }}
        </label>
        <v-radio-group
          v-model="questions.question9"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('clt_employee.no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('clt_employee.yes')"
            :value="true"
          />
        </v-radio-group>
      </div>
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('has_voluntary.label') }}
        </label>
        <v-textarea
          v-model="questions.question10"
          flat
          background-color="field_register"
          class="input-register mt-4"
          :label="mountTranslate('has_voluntary.placeholder')"
          solo
        />
      </div>
      <div class="button-access">
        <rectoplus-button
          id="nextSubStep1"
          class="next-button"
          :text="$t('components.register.business.organization.button')"
          @click="nextStep"
        />
      </div>
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'FormAdministrative',
  components: {
    RectoplusButton,
  },
  data() {
    return {
      questions: {
        question1: {
          hasCompany: 'cooperative',
          other: '',
        },
        question2: {
          hasCooperativeNetwork: false,
          name: '',
        },
        question3: {
          hasSoftware: false,
          name: '',
        },
        question4: false,
        question5: '',
        question6: {
          hasQuotas: false,
          hasReserveFund: false,
          hasNone: false,
          hasOther: false,
          nameOther: '',
        },
        question7: {
          hasAttorney: false,
          nameAttorney: '',
          contactAttorney: '',
        },
        question8: {
          hasCounter: false,
          contactCounter: '',
          nameCounter: '',
        },
        question9: false,
        question10: '',
      },
    };
  },
  computed: {
    ...mapGetters(['getAdministrative', 'hasOperationalCapacity']),
  },
  mounted() {
    if (this.hasOperationalCapacity) this.setQuestions();
  },
  methods: {
    ...mapActions([
      'nextSubStep',
      'setQuestionAdministrative',
    ]),
    mountTranslate(path) {
      return this.$t(`components.stepper_business.sub_steps.administrative.form.${path}`);
    },
    nextStep() {
      this.setQuestionAdministrative(this.questions);
      this.nextSubStep();
    },
    setQuestions() {
      Object.keys(this.questions).forEach((key) => {
        this.questions[key] = this.getAdministrative[key];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .administrative-form {
    color: $color_field_quiz;
    font-size: 0.875em;

    .content-field {
      margin-bottom: 20px;
    }

    .radio-company {
      font-weight: bold;
    }

    .check-financial-structure {
      display: flex;
      align-items: flex-end;

      .label-terms {
        font-size: 1.095em;
        font-weight: bold;
        margin-left: 11px;
      }
    }

    .title-field {
      font-size: 1.25em;
      font-weight: 600;
    }

    .button-access {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 40px;

      .next-button {
        width: 100%;
        height: 70px;
        margin-bottom: 20px;
      }
    }

    .input-register{
      border-radius: 8px;
    }

    .input-contact {
      border-radius: 8px;
      width: 50%;
    }
  }
</style>
