<template>
  <div class="health-security">
    <p class="title-text">
      {{ $t('components.stepper_business.sub_steps.title') }}
    </p>

    <p class="subtitle-text">
      {{ $t('components.stepper_business.sub_steps.health_security.title') }}
    </p>
    <form-health-security :is-cooperative="checkBusinessKind" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import FormHealthSecurity from '@/components/forms/register/business/operational_capacity/FormHealthSecurity.vue';

export default {
  name: 'HealthSecurity',
  components: {
    FormHealthSecurity,
  },
  computed: {
    ...mapGetters([
      'getBusiness',
    ]),
    checkBusinessKind() {
      return this.getBusiness.kind === 'cooperative_association';
    },
  },
};
</script>

<style lang="scss" scoped>
.health-security {
  padding-left: 8px;
  margin: 20px;
  .title-text {
    color: $title_color_primary;
    margin-bottom: 64px;
    margin-top: 40px;
    font-size: 1.8em;
    font-weight: bold;
  }
  .subtitle-text {
    color: $title_color_primary;
    margin-bottom: 30px;
    font-size: 1.5em;
    font-weight: 600;
  }
}
</style>
