<template>
  <div class="container-steps">
    <div class="aside">
      <div class="timeline" />
      <div class="circles">
        <div
          v-for="item in subSteps"
          :key="item.step"
        >
          <span :class="[stateSubStep(item.step), 'circle']" />
        </div>
      </div>
    </div>
    <div class="content">
      <span
        v-for="item in subSteps"
        :key="item.step"
        :class="stateSubStep(item.step)"
      >
        {{ item.title }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HeaderSubSteps',
  props: {
    currentSubStep: {
      type: Number,
      default: null,
    },
    subSteps: {
      type: Array,
      default: () => {},
    },
  },
  methods: {
    stateSubStep(step) {
      if (step === this.currentSubStep) return 'selected';
      if (step < this.currentSubStep) return 'disable';
      if (step > this.currentSubStep) return 'previous';
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
.container-steps {
  margin-top: 20px;
  height: 248px;
  display: flex;
  margin-left: 73px;
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 500;
    font-size: 0.9em;
    color: $color_primary;
    span {
      height: 20px;
      display: flex;
      align-items: center;
      color: $step_disabled;
      &.selected {
        color: $color_primary;
      }
      &.disable {
        color: $step_disabled;
      }
    }
  }
  .aside {
    height: 100%;
    width: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    .timeline {
      height: 97%;
      width: 4px;
      background: $step_disabled;
      position: absolute;
    }
    .circles {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      .circle {
        display: block;
        width: 20px;
        height: 20px;
        border: 4px solid #8F8F8F;
        border-radius: 50%;
        background: $step_disabled;
        border-color: $step_disabled;
        position: relative;
        &.selected {
          background: $color_primary;
          border-color: $color_primary;
        }
        &.disable {
          border-color: $step_disabled;
        }
      }
    }
  }
}
</style>
