<template>
  <div class="conclusion-form text-start">
    <v-form>
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('maximum_operating_capacity.label') }}
        </label>
        <v-textarea
          v-model="questions.question1"
          flat
          background-color="field_register"
          class="input-register mt-4"
          :label="mountTranslate('maximum_operating_capacity.placeholder')"
          solo
        />
      </div>

      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('date_of_affection.label') }}
        </label>
        <v-text-field
          v-model="questions.question2"
          v-mask="'##/##/####'"
          flat
          background-color="field_register"
          class="input-register field-date mt-4"
          :label="mountTranslate('date_of_affection.placeholder')"
          solo
        />
      </div>

      <div class="button-access">
        <rectoplus-button
          id="nextSubStep6"
          class="next-button"
          :text="$t('components.register.business.conclusion.button')"
          @click="nextStep"
        />
      </div>
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'FormConclusion',
  components: {
    RectoplusButton,
  },
  data() {
    return {
      questions: {
        question1: '',
        question2: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getQuestions',
      'getBusiness',
      'getConclusion',
      'hasOperationalCapacity',
    ]),
  },
  mounted() {
    if (this.hasOperationalCapacity) this.setQuestions();
  },
  methods: {
    ...mapActions([
      'mountQuestion',
      'sendQuestions',
      'initLoading',
      'setQuestionConclusion',
    ]),
    mountTranslate(path) {
      return this.$t(`components.stepper_business.sub_steps.conclusion.form.${path}`);
    },
    nextStep() {
      this.initLoading();
      this.setQuestionConclusion(this.questions);
      this.mountQuestion();
      const params = {
        operational_capacity: JSON.stringify(this.getQuestions),
        id: this.getBusiness.id,
      };
      this.sendQuestions(params).then(() => this.$router.push('/home'));
    },
    setQuestions() {
      Object.keys(this.questions).forEach((key) => {
        this.questions[key] = this.getConclusion[key];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.conclusion-form {
  color: $color_field_quiz;
  font-size: 0.875em;

  .radio-default {
    font-weight: bold;
  }
  .field-date {
    width: 168px;
  }
  .content-field {
    margin-bottom: 20px;
  }
  .title-field {
    font-size: 1.25em;
    font-weight: 600;
  }
  .button-access {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 40px;
    .next-button {
      width: 100%;
      height: 70px;
      margin-bottom: 20px;
    }
  }
  .input-register{
    border-radius: 8px;
  }
}
</style>
