<template>
  <div class="health-security-form text-start">
    <v-form>
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('company.label') }}
        </label>
        <v-radio-group
          v-model="questions.question1"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('company.no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('company.yes')"
            :value="true"
          />
        </v-radio-group>
      </div>

      <div class="content-field">
        <label
          class="title-field"
        >
          {{ mountTranslate('training.label') }}
        </label>
        <div>
          <v-select
            v-model="questions.question2"
            :items="frequenceSelect"
            :placeholder="mountTranslate('training.selects.placeholder')"
            class="select-register mt-4"
            background-color="white"
            outlined
          />
        </div>
      </div>

      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('info_training.label') }}
        </label>
        <v-textarea
          v-model="questions.question3"
          flat
          background-color="field_register"
          class="input-register mt-4"
          :label="mountTranslate('info_training.placeholder')"
          solo
        />
      </div>

      <div class="content-field">
        <label
          class="title-field"
        >
          {{ mountTranslate('frequence_epi.label') }}
        </label>
        <div>
          <v-select
            v-model="questions.question4"
            :items="frequenceEpiSelect"
            :placeholder="mountTranslate('frequence_epi.selects.placeholder')"
            class="select-register mt-4"
            background-color="white"
            outlined
          />
        </div>
      </div>

      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('screening.label') }}
        </label>

        <div class="check-screening">
          <v-checkbox
            v-model="questions.question5.hasCover"
            color="primary"
            hide-details
          />
          <div class="label-terms">
            {{ mountTranslate('screening.cover') }}
          </div>
        </div>

        <div class="check-screening">
          <v-checkbox
            v-model="questions.question5.hasFireExtinguisher"
            color="primary"
            hide-details
          />
          <div class="label-terms">
            {{ mountTranslate('screening.fire_extinguisher') }}
          </div>
        </div>

        <div class="check-screening">
          <v-checkbox
            v-model="questions.question5.hasEmergencyExit"
            color="primary"
            hide-details
          />
          <div class="label-terms">
            {{ mountTranslate('screening.emergency_exit') }}
          </div>
        </div>

        <div class="check-screening">
          <v-checkbox
            v-model="questions.question5.hasWaterproofFloor"
            color="primary"
            hide-details
          />
          <div class="label-terms">
            {{ mountTranslate('screening.waterproof_floor') }}
          </div>
        </div>

        <div class="check-screening">
          <v-checkbox
            v-model="questions.question5.hasAdequateLighting"
            color="primary"
            hide-details
          />
          <div class="label-terms">
            {{ mountTranslate('screening.adequate_lighting') }}
          </div>
        </div>

        <div class="check-screening">
          <v-checkbox
            v-model="questions.question5.hasExhaustFans"
            color="primary"
            hide-details
          />
          <div class="label-terms">
            {{ mountTranslate('screening.exhaust_fans') }}
          </div>
        </div>
      </div>

      <div
        v-if="isCooperative"
        class="content-field"
      >
        <label class="title-field">
          {{ mountTranslate('sorting_unit.label') }}
        </label>
        <v-radio-group
          v-model="questions.question6.hasSortingUnit"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('sorting_unit.no')"
            value="no"
          />
          <v-radio
            class="mb-5"
            :label="mountTranslate('sorting_unit.in_process')"
            value="in_process"
          />
          <v-radio
            :label="mountTranslate('sorting_unit.yes')"
            value="yes"
          />
        </v-radio-group>
        <v-text-field
          v-if="questions.question6.hasSortingUnit === 'yes'"
          v-model="questions.question6.sortingUnitComplement"
          flat
          background-color="field_register"
          class="input-register"
          :label="mountTranslate('sorting_unit.placeholder')"
          solo
        />
      </div>

      <div class="button-access">
        <rectoplus-button
          id="nextSubStep3"
          class="next-button"
          :text="$t('components.register.business.organization.button')"
          @click="nextStep"
        />
      </div>
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'FormHealthSecurity',
  components: {
    RectoplusButton,
  },
  props: {
    isCooperative: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      questions: {
        question1: false,
        question2: '',
        question3: '',
        question4: '',
        question5: {
          hasCover: false,
          hasFireExtinguisher: false,
          hasEmergencyExit: false,
          hasWaterproofFloor: false,
          hasAdequateLighting: false,
          hasExhaustFans: false,
        },
        question6: {
          hasSortingUnit: false,
          sortingUnitComplement: '',
        },
      },
      frequenceSelect: [
        { text: this.mountTranslate('training.selects.select_0'), value: this.mountTranslate('training.selects.select_0') },
        { text: this.mountTranslate('training.selects.select_1'), value: this.mountTranslate('training.selects.select_1') },
        { text: this.mountTranslate('training.selects.select_2'), value: this.mountTranslate('training.selects.select_2') },
        { text: this.mountTranslate('training.selects.select_3'), value: this.mountTranslate('training.selects.select_3') },
        { text: this.mountTranslate('training.selects.select_4'), value: this.mountTranslate('training.selects.select_4') },
        { text: this.mountTranslate('training.selects.select_5'), value: this.mountTranslate('training.selects.select_5') },
        { text: this.mountTranslate('training.selects.select_6'), value: this.mountTranslate('training.selects.select_6') },
        { text: this.mountTranslate('training.selects.select_7'), value: this.mountTranslate('training.selects.select_7') },
        { text: this.mountTranslate('training.selects.select_8'), value: this.mountTranslate('training.selects.select_8') },
      ],
      frequenceEpiSelect: [
        { text: this.mountTranslate('frequence_epi.selects.select_0'), value: this.mountTranslate('frequence_epi.selects.select_0') },
        { text: this.mountTranslate('frequence_epi.selects.select_1'), value: this.mountTranslate('frequence_epi.selects.select_1') },
        { text: this.mountTranslate('frequence_epi.selects.select_2'), value: this.mountTranslate('frequence_epi.selects.select_2') },
        { text: this.mountTranslate('frequence_epi.selects.select_3'), value: this.mountTranslate('frequence_epi.selects.select_3') },
        { text: this.mountTranslate('frequence_epi.selects.select_4'), value: this.mountTranslate('frequence_epi.selects.select_4') },
        { text: this.mountTranslate('frequence_epi.selects.select_5'), value: this.mountTranslate('frequence_epi.selects.select_5') },
        { text: this.mountTranslate('frequence_epi.selects.select_6'), value: this.mountTranslate('frequence_epi.selects.select_6') },
      ],
    };
  },
  computed: {
    ...mapGetters(['getHealthSecurity', 'hasOperationalCapacity']),
  },
  mounted() {
    if (this.hasOperationalCapacity) this.setQuestions();
  },
  methods: {
    ...mapActions([
      'nextSubStep',
      'setQuestionHealthSecurity',
    ]),
    mountTranslate(path) {
      return this.$t(`components.stepper_business.sub_steps.health_security.form.${path}`);
    },
    nextStep() {
      this.setQuestionHealthSecurity(this.questions);
      this.nextSubStep();
    },
    setQuestions() {
      Object.keys(this.questions).forEach((key) => {
        this.questions[key] = this.getHealthSecurity[key];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.health-security-form {
  color: $color_field_quiz;
  font-size: 0.875em;

  .select-register {
    color: $color_primary;
    border-radius: 10px;
    width: 100%;
  }
  .radio-company {
    font-weight: bold;
  }
  .content-field {
    margin-bottom: 20px;
  }
  .title-field {
    font-size: 1.25em;
    font-weight: 600;
  }
  .check-screening {
    display: flex;
    align-items: flex-end;

    .label-terms {
      font-size: 1.095em;
      font-weight: bold;
      margin-left: 11px;
    }
  }

  .button-access {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 40px;

    .next-button {
      width: 100%;
      height: 70px;
      margin-bottom: 20px;
    }
  }

  .input-register{
    border-radius: 8px;
  }
}
</style>
