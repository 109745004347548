<template>
  <div class="programs-partnerships-form text-start">
    <v-form>
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('logistic_operator.label') }}
        </label>
        <v-textarea
          v-model="questions.question1"
          flat
          background-color="field_register"
          class="input-register mt-4"
          :label="mountTranslate('logistic_operator.placeholder')"
          solo
        />
      </div>

      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('has_partnership.label') }}
        </label>
        <v-textarea
          v-model="questions.question2"
          flat
          background-color="field_register"
          class="input-register mt-4"
          :label="mountTranslate('has_partnership.placeholder')"
          solo
        />
      </div>

      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('ong.label') }}
        </label>
        <v-textarea
          v-model="questions.question3"
          flat
          background-color="field_register"
          class="input-register mt-4"
          :label="mountTranslate('ong.placeholder')"
          solo
        />
      </div>

      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('private_business_support.label') }}
        </label>
        <v-textarea
          v-model="questions.question4"
          flat
          background-color="field_register"
          class="input-register mt-4"
          :label="mountTranslate('private_business_support.placeholder')"
          solo
        />
      </div>

      <div class="button-access">
        <rectoplus-button
          class="next-button"
          :text="$t('components.register.business.organization.button')"
          @click="nextStep"
        />
      </div>
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'WasteMerchantFormProgramsPartnerships',
  components: {
    RectoplusButton,
  },
  data() {
    return {
      questions: {
        question1: '',
        question2: '',
        question3: '',
        question4: '',
      },
    };
  },
  computed: {
    ...mapGetters(['getProgramsPartnerships', 'hasOperationalCapacity']),
  },
  mounted() {
    if (this.hasOperationalCapacity) this.setQuestions();
  },
  methods: {
    ...mapActions([
      'nextSubStep',
      'setQuestionProgramsPartnerships',
    ]),
    mountTranslate(path) {
      return this.$t(`components.stepper_business.sub_steps.waste_merchant.programs_partnerships.form.${path}`);
    },
    nextStep() {
      this.setQuestionProgramsPartnerships(this.questions);
      this.nextSubStep();
    },
    setQuestions() {
      Object.keys(this.questions).forEach((key) => {
        this.questions[key] = this.getProgramsPartnerships[key];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .programs-partnerships-form {
    color: $color_field_quiz;
    font-size: 0.875em;

    .radio-default {
      font-weight: bold;
    }
    .content-field {
      margin-bottom: 20px;
    }
    .title-field {
      font-size: 1.25em;
      font-weight: 600;
    }
    .button-access {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 40px;
      .next-button {
        width: 100%;
        height: 70px;
        margin-bottom: 20px;
      }
    }
    .input-register{
      border-radius: 8px;
    }
  }
</style>
