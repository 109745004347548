<template>
  <div class="sub-stepper-business">
    <v-stepper
      v-model="currentSubStep"
      flat
      tile
      class="stepper"
    >
      <back-button
        :text="$t('components.labels.btn_back')"
        @click="backSubSteps"
      />
      <v-stepper-items class="pa-0">
        <v-stepper-content
          step="1"
        >
          <administrative />
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-items class="pa-0">
        <v-stepper-content
          step="2"
        >
          <operating-equipment />
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-items class="pa-0">
        <v-stepper-content
          step="3"
        >
          <health-security />
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-items class="pa-0">
        <v-stepper-content
          step="4"
        >
          <waste-destination />
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-items class="pa-0">
        <v-stepper-content
          step="5"
        >
          <programs-partnerships />
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-items class="pa-0">
        <v-stepper-content
          step="6"
        >
          <conclusion />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import BackButton from '@/components/buttons/BackButton.vue';
import Administrative from '@/components/cards/business/operational_capacity/Administrative.vue';
import OperatingEquipment from '@/components/cards/business/operational_capacity/OperatingEquipment.vue';
import HealthSecurity from '@/components/cards/business/operational_capacity/HealthSecurity.vue';
import WasteDestination from '@/components/cards/business/operational_capacity/WasteDestination.vue';
import ProgramsPartnerships from '@/components/cards/business/operational_capacity/ProgramsPartnerships.vue';
import Conclusion from '@/components/cards/business/operational_capacity/Conclusion.vue';

export default {
  name: 'SubStep',
  components: {
    Conclusion,
    ProgramsPartnerships,
    Administrative,
    OperatingEquipment,
    HealthSecurity,
    WasteDestination,
    BackButton,
  },
  props: {
    currentSubStep: {
      type: Number,
      default: null,
    },
  },
  mounted() {
    this.setSubStepsBusiness();
  },
  methods: {
    ...mapActions([
      'nextSubStep',
      'backSubStepBusiness',
      'setSubStepsBusiness',
    ]),
    backSubSteps() {
      this.backSubStepBusiness();
    },
  },
};
</script>
<style lang="scss" scoped>
.sub-stepper-business {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .stepper{
    max-width: 760px;
    background: transparent;
  }
}
</style>
