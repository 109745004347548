<template>
  <div class="step-business">
    <div
      v-show="!$vuetify.breakpoint.smAndDown"
      class="head-step"
    >
      <div>
        <chip-step-register
          v-for="chip in allSteps"
          :key="chip.step"
          :content-step="chip"
          :current-step="getStep"
        />
        <div v-if="getStep >= 4">
          <header-sub-steps
            :current-sub-step="getSubStep"
            :sub-steps="subSteps"
          />
        </div>
      </div>
    </div>
    <!-- Para nao quebra no mobile, criei um novo head -->
    <div
      v-show="$vuetify.breakpoint.smAndDown"
    >
      <div class="head-step-sm">
        <div
          v-for="chip in allSteps"
          :id="chip.step"
          :key="chip.step"
          :class="['content', chipSelected(chip)]"
        >
          <div>
            {{ chip.step }}
          </div>
          <div>
            {{ chip.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="content-step">
      <v-stepper
        v-model="getStep"
        flat
        tile
        class="stepper"
      >
        <v-stepper-items class="pa-0">
          <v-stepper-content
            step="2"
            class="organization"
          >
            <data-organization
              @registerDataBusiness="registerDataOrganization"
            />
          </v-stepper-content>
        </v-stepper-items>

        <v-stepper-items class="pa-0">
          <v-stepper-content
            step="3"
            class="organization"
          >
            <documents-organization
              @submitDocuments="registerDocumentsOrganization"
            />
          </v-stepper-content>
        </v-stepper-items>

        <v-stepper-items class="pa-0">
          <v-stepper-content
            step="4"
          >
            <sub-step
              :current-sub-step="getSubStep"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ChipStepRegister from '@/components/stepps/ChipStepRegister.vue';
import SubStep from '@/components/stepps/business/SubStepOperationalCapacity.vue';
import DataOrganization from '@/components/cards/business/DataBusiness.vue';
import DocumentsOrganization from '@/components/cards/business/DocumentsBusiness.vue';
import HeaderSubSteps from '@/components/headers/business/HeaderSubSteps.vue';

export default {
  name: 'StepperBusiness',
  components: {
    ChipStepRegister,
    SubStep,
    DocumentsOrganization,
    DataOrganization,
    HeaderSubSteps,
  },
  data() {
    return {
      allSteps: [
        { step: 1, title: this.$t('components.stepper_business.steps.identification') },
        { step: 2, title: this.$t('components.stepper_business.steps.business_data') },
        { step: 3, title: this.$t('components.stepper_business.steps.documents') },
        { step: 4, title: this.$t('components.stepper_business.steps.operational_capacity') },
      ],
      subSteps: [
        { step: 1, title: this.$t('components.stepper_business.sub_steps.administrative.step') },
        { step: 2, title: this.$t('components.stepper_business.sub_steps.operating_equipment.step') },
        { step: 3, title: this.$t('components.stepper_business.sub_steps.health_security.step') },
        { step: 4, title: this.$t('components.stepper_business.sub_steps.waste_destination.step') },
        { step: 5, title: this.$t('components.stepper_business.sub_steps.programs_partnerships.step') },
        { step: 6, title: this.$t('components.stepper_business.sub_steps.conclusion.step') },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getStep',
      'getSubStep',
      'getBusiness',
    ]),
  },
  watch: {
    getSubStep(value) {
      document.getElementsByClassName('content-step')[0].scrollTo(0, 0);
      if (value < 1) this.$router.push('/home');
    },
  },
  mounted() {
    if (!this.getBusiness) this.$router.push('/home');
    const businesses = this.getBusiness;
    if (businesses?.id) {
      this.setStepsBusiness(4);
    } else {
      this.setStepsBusiness(2);
    }
  },
  methods: {
    ...mapActions([
      'registerOrganization',
      'sendDocumentsFiles',
      'initLoading',
      'setStepsBusiness',
      'addSuccessMessage',
      'backSubStep',
    ]),
    registerDataOrganization(payload) {
      this.initLoading();
      this.registerOrganization(payload);
    },
    registerDocumentsOrganization(payload) {
      this.initLoading();
      this.sendDocumentsFiles(payload);
    },

    chipSelected(chip) {
      return chip.step === this.getStep ? 'select' : '';
    },
  },
};
</script>
<style lang="scss" scoped>
.step-business {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: auto;
  height: 100vh;
  .stepper{
    background: transparent;
    .organization {
      padding-top: 172px;
    }
  }
  .head-step-sm {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding: 12px;
    background-color: $step_register_campany;
    .content {
      margin-right: 52px;
    }
    .select {
      color: white;
    }
  }
  .head-step {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $step_register_campany;
  }
  .content-step {
    max-height: 100vh;
    margin-left: 40px;
    padding: 0px 50px;
    overflow-y: scroll;
  }
}
// vuetify breakpoint
@media #{map-get($display-breakpoints, 'sm-and-down')} {
 .step-business {
   display: flex;
   flex-direction: column;
    .content-step {
      height: 100%;
      padding-left: 12px;
    }
  }
}
</style>
