<template>
  <div class="operating-equipament-form">
    <v-form>
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('company.label') }}
        </label>
        <v-text-field
          v-model="questions.question1"
          flat
          background-color="field_register"
          class="input-register input-company mt-2"
          :label="mountTranslate('company.placeholder')"
          solo
        />
      </div>
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('shifts.label') }}
        </label>
        <v-textarea
          v-model="questions.question2"
          flat
          background-color="field_register"
          class="input-register mt-4"
          :label="mountTranslate('shifts.placeholder')"
          solo
        />
      </div>
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('risk_map.label') }}
        </label>
        <v-radio-group
          v-model="questions.question3"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('risk_map.no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('risk_map.yes')"
            :value="true"
          />
        </v-radio-group>
      </div>
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('division_work.label') }}
        </label>
        <v-textarea
          v-model="questions.question4"
          flat
          background-color="field_register"
          class="input-register mt-4"
          :label="mountTranslate('division_work.placeholder')"
          solo
        />
      </div>
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('marketing_materials.label') }}
        </label>
        <v-radio-group
          v-model="questions.question5.marketingMaterials"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('marketing_materials.day')"
            value="daily"
          />
          <v-radio
            class="mb-5"
            :label="mountTranslate('marketing_materials.week')"
            value="weekly"
          />
          <v-radio
            class="mb-5"
            :label="mountTranslate('marketing_materials.biweekly')"
            value="biweekly"
          />
          <v-radio
            class="mb-5"
            :label="mountTranslate('marketing_materials.monthly')"
            value="monthly"
          />
          <v-radio
            class="mb-5"
            :label="mountTranslate('marketing_materials.annually')"
            value="annually"
          />
          <v-radio
            :label="mountTranslate('marketing_materials.other')"
            value="other"
          />
        </v-radio-group>
        <div
          v-if="questions.question5.marketingMaterials === 'other'"
        >
          <v-text-field
            v-model="questions.question5.other"
            flat
            background-color="field_register"
            class="input-register"
            :label="mountTranslate('marketing_materials.placeholder')"
            solo
          />
        </div>
      </div>
      <div class="content-field">
        <label
          class="title-field"
        >
          {{ mountTranslate('paper.term_1') }}
          <div class="text-decorator">{{ mountTranslate('paper.term_2') }}</div>
          {{ mountTranslate('paper.term_3') }}
        </label>
        <div class="content-field">
          <v-select
            id="select-paper"
            v-model="questions.question6"
            :items="tonsSelect"
            :placeholder="mountTranslate('tons.placeholder')"
            class="select-register mt-4"
            background-color="white"
            outlined
          />
        </div>
      </div>
      <div class="content-field">
        <label
          class="title-field"
        >
          {{ mountTranslate('plastic.term_1') }}
          <div class="text-decorator">{{ mountTranslate('plastic.term_2') }}</div>
          {{ mountTranslate('plastic.term_3') }}
        </label>
        <div class="content-field">
          <v-select
            id="select-plastic"
            v-model="questions.question7"
            :items="tonsSelect"
            :placeholder="mountTranslate('tons.placeholder')"
            class="select-register mt-4"
            background-color="white"
            outlined
          />
        </div>
      </div>
      <div class="content-field">
        <label
          class="title-field"
        >
          {{ mountTranslate('metal.term_1') }}
          <div class="text-decorator">{{ mountTranslate('metal.term_2') }}</div>
          {{ mountTranslate('metal.term_3') }}
        </label>
        <div class="content-field">
          <v-select
            v-model="questions.question8"
            :items="tonsSelect"
            :placeholder="mountTranslate('tons.placeholder')"
            class="select-register mt-4"
            background-color="white"
            outlined
          />
        </div>
      </div>
      <div class="content-field">
        <label
          class="title-field"
        >
          {{ mountTranslate('glass.term_1') }}
          <div class="text-decorator">{{ mountTranslate('glass.term_2') }}</div>
          {{ mountTranslate('glass.term_3') }}
        </label>
        <div class="content-field">
          <v-select
            id="select-glass"
            v-model="questions.question9"
            :items="tonsSelect"
            :placeholder="mountTranslate('tons.placeholder')"
            class="select-register mt-4"
            background-color="white"
            outlined
          />
        </div>
      </div>
      <div class="content-field">
        <label
          class="title-field"
        >
          {{ mountTranslate('aerossol_packages_screening.term_1') }}
          <div class="text-decorator">
            {{ mountTranslate('aerossol_packages_screening.term_2') }}
          </div>
          {{ mountTranslate('aerossol_packages_screening.term_3') }}
          <div class="text-decorator">
            {{ mountTranslate('aerossol_packages_screening.term_4') }}
          </div>
          {{ mountTranslate('aerossol_packages_screening.term_5') }}
        </label>
        <v-radio-group
          v-model="questions.question10"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('aerossol_packages_screening.no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('aerossol_packages_screening.yes')"
            :value="true"
          />
        </v-radio-group>
      </div>
      <div class="content-field">
        <label
          class="title-field"
        >
          {{ mountTranslate('aerossol.term_1') }}
          <div class="text-decorator">{{ mountTranslate('aerossol.term_2') }}</div>
          {{ mountTranslate('aerossol.term_3') }}
        </label>
        <div class="content-field">
          <v-select
            id="select-aerossol"
            v-model="questions.question11"
            :items="tonsSelect"
            :placeholder="mountTranslate('tons.placeholder')"
            class="select-register mt-4"
            background-color="white"
            outlined
          />
        </div>
      </div>
      <div class="content-field">
        <label
          class="title-field"
        >
          {{ mountTranslate('mats.term_1') }}
          <div class="text-decorator">{{ mountTranslate('mats.term_2') }}</div>
          {{ mountTranslate('mats.term_3') }}
        </label>
        <div class="content-field">
          <v-select
            id="select-mats"
            v-model="questions.question12"
            :items="operatesSelect"
            :placeholder="mountTranslate('operates.placeholder')"
            class="select-register mt-4"
            background-color="white"
            outlined
          />
        </div>
      </div>
      <div class="content-field">
        <label
          class="title-field"
        >
          {{ mountTranslate('presses.term_1') }}
          <div class="text-decorator">{{ mountTranslate('presses.term_2') }}</div>
          {{ mountTranslate('presses.term_3') }}
        </label>
        <div class="content-field">
          <v-select
            id="select-presses"
            v-model="questions.question13"
            :items="operatesSelect"
            :placeholder="mountTranslate('operates.placeholder')"
            class="select-register mt-4"
            background-color="white"
            outlined
          />
        </div>
      </div>
      <div class="content-field">
        <label
          class="title-field"
        >
          {{ mountTranslate('scales.term_1') }}
          <div class="text-decorator">{{ mountTranslate('scales.term_2') }}</div>
          {{ mountTranslate('scales.term_3') }}
        </label>
        <div class="content-field">
          <v-select
            id="select-scales"
            v-model="questions.question14"
            :items="operatesSelect"
            :placeholder="mountTranslate('operates.placeholder')"
            class="select-register mt-4"
            background-color="white"
            outlined
          />
        </div>
      </div>
      <div class="content-field">
        <label class="title-field">
          {{ mountTranslate('calibration_certified.label') }}
        </label>
        <v-radio-group
          v-model="questions.question15"
          class="radio-company"
        >
          <v-radio
            class="mb-5"
            :label="mountTranslate('calibration_certified.no')"
            :value="false"
          />
          <v-radio
            :label="mountTranslate('calibration_certified.yes')"
            :value="true"
          />
        </v-radio-group>
      </div>
      <div class="button-access">
        <rectoplus-button
          id="nextSubStep2"
          class="next-button"
          :text="$t('components.register.business.organization.button')"
          @click="nextStep"
        />
      </div>
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'FormOperatingEquipament',
  components: {
    RectoplusButton,
  },
  data() {
    return {
      questions: {
        question1: '',
        question2: '',
        question3: false,
        question4: '',
        question5: {
          marketingMaterials: '',
          other: '',
        },
        question6: null,
        question7: null,
        question8: null,
        question9: null,
        question10: false,
        question11: null,
        question12: null,
        question13: null,
        question14: null,
        question15: false,
      },
      tonsSelect: [
        { text: this.mountTranslate('tons.select_0'), value: this.mountTranslate('tons.select_0') },
        { text: this.mountTranslate('tons.select_1'), value: this.mountTranslate('tons.select_1') },
        { text: this.mountTranslate('tons.select_2'), value: this.mountTranslate('tons.select_2') },
        { text: this.mountTranslate('tons.select_3'), value: this.mountTranslate('tons.select_3') },
        { text: this.mountTranslate('tons.select_4'), value: this.mountTranslate('tons.select_4') },
        { text: this.mountTranslate('tons.select_5'), value: this.mountTranslate('tons.select_5') },
        { text: this.mountTranslate('tons.select_6'), value: this.mountTranslate('tons.select_6') },
        { text: this.mountTranslate('tons.select_7'), value: this.mountTranslate('tons.select_7') },
      ],
      operatesSelect: [
        { text: this.mountTranslate('operates.select_1'), value: this.mountTranslate('operates.select_1') },
        { text: this.mountTranslate('operates.select_2'), value: this.mountTranslate('operates.select_2') },
        { text: this.mountTranslate('operates.select_3'), value: this.mountTranslate('operates.select_3') },
        { text: this.mountTranslate('operates.select_4'), value: this.mountTranslate('operates.select_4') },
        { text: this.mountTranslate('operates.select_5'), value: this.mountTranslate('operates.select_5') },
        { text: this.mountTranslate('operates.select_6'), value: this.mountTranslate('operates.select_6') },
      ],
      operatesSelectDefault: { text: this.mountTranslate('operates.placeholder'), value: null },
    };
  },
  computed: {
    ...mapGetters(['getOperatingEquipment', 'hasOperationalCapacity']),
  },
  mounted() {
    if (this.hasOperationalCapacity) this.setQuestions();
  },
  methods: {
    ...mapActions([
      'nextSubStep',
      'setQuestionOperatingEquipment',
    ]),
    mountTranslate(path) {
      return this.$t(`components.stepper_business.sub_steps.operating_equipment.form.${path}`);
    },
    nextStep() {
      this.setQuestionOperatingEquipment(this.questions);
      this.nextSubStep();
    },
    setQuestions() {
      Object.keys(this.questions).forEach((key) => {
        this.questions[key] = this.getOperatingEquipment[key];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.operating-equipament-form {
  color: $color_field_quiz;
  font-size: 0.875em;

  .content-field {
    margin-bottom: 20px;
  }
  .select-register {
    color: $color_primary;
    border-radius: 10px;
    width: 100%;
  }
  .radio-company {
    font-weight: bold;
  }
  .title-field {
    font-size: 1.25em;
    font-weight: 600;
    .text-decorator {
      display: inline;
      color: $color_primary;
    }
  }
  .input-register{
    border-radius: 8px;
  }
  .input-company {
    width: 220px;
  }
  .button-access {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 40px;
    .next-button {
      width: 100%;
      height: 70px;
      margin-bottom: 20px;
    }
  }
}
</style>
