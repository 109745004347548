<template>
  <div class="register-company">
    <stepper-business />
  </div>
</template>
<script>
import StepperBusiness from '@/components/stepps/StepperBusiness.vue';

export default {
  name: 'Business',
  components: {
    StepperBusiness,
  },
};
</script>
<style lang="scss" scoped>
.register-company {
  height: 100vh;
}
</style>
